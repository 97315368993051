import React, { useCallback, useEffect, useState } from 'react'
import { useField, useFormikContext } from 'formik'
import { debounce, capitalize } from 'lodash-es'
import { api } from 'components/api'
import PropTypes from 'prop-types'
import indefinite from 'indefinite'

export interface SelectSearchableRecordProps {
  label: string
  name: string
  recordSearchPath: string
  searchFields?: string[]
  minimumSearchTermLength?: number
  id?: string
  idFieldName?: string
  nameFieldName?: string
  placeholder?: string
  stateCode?: string
  allowIdSearch?: boolean
  additionalSearchQueryParams?: Record<string, string | boolean>
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

export interface SearchResult {
  id: string
  name: string
  location?: string
}

const SelectSearchableRecord: React.FC<SelectSearchableRecordProps> = ({ name, searchFields = ['name'], minimumSearchTermLength = 3, allowIdSearch = false, ...props }) => {
  // eslint-disable-next-line no-unused-vars
  const [, meta] = useField(name)
  const { values, setFieldValue } = useFormikContext<Record<string, string>>()

  const [recordName, setRecordName] = useState('')
  const [recordId, setRecordId] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [searchResults, setSearchResults] = useState<SearchResult[]>([])
  const [hasSavedRecord, setHasSavedRecord] = useState<null | boolean>(null)

  const id = props.id ?? `select-${name}`
  const label = props.label ?? `${capitalize(name)} Name`
  const placeholder = props.placeholder ?? `${capitalize(name)} Name${allowIdSearch ? ' or ID' : ''}`
  const idFieldName = props.idFieldName ?? `${name}_id`
  const nameFieldName = props.nameFieldName ?? `${name}_name`
  const derivedSearchFields = allowIdSearch
    ? ['id', ...searchFields.filter((field) => field !== 'id')]
    : searchFields

  useEffect(() => {
    setFieldValue(idFieldName, recordId).catch(() => console.error('Error setting record id'))
  }, [recordId])

  useEffect(() => {
    setFieldValue(nameFieldName, recordName).catch(() => console.error('Error setting record name'))
  }, [recordName])

  useEffect(() => {
    executeSearch()
    updateRecordName()
  }, [values[name]])

  const isIdSearch = (searchTerm = values[name]): boolean => {
    return allowIdSearch && searchTerm !== undefined && searchTerm !== null && !isNaN(Number(searchTerm))
  }

  const searchFn = useCallback(
    debounce(async (term, params) => {
      const response = await api.get<unknown, SearchResult[]>({ url: props.recordSearchPath, params: { query: term, fields: derivedSearchFields, ...params } })
      setIsSearching(true)
      setSearchResults(response.data)
    }, 300), []
  )

  const clearSearch = (): void => {
    setIsSearching(false)
    setSearchResults([])
  }

  const updateRecordName = (): void => {
    const searchTerm = values[name]

    if (isIdSearch(searchTerm)) {
      setRecordId(searchTerm)
      setRecordName('')
    } else {
      setRecordId('')
      setRecordName(searchTerm)
    }
  }

  const executeSearch = (): void => {
    const searchTerm = values[name]
    if (searchTerm.length === 0) {
      setIsSearching(false)
      setSearchResults([])
      return
    } else if (searchTerm.length < minimumSearchTermLength) {
      return
    }

    void searchFn(searchTerm, props.additionalSearchQueryParams)
  }

  const clearRecordId = (): void => {
    isIdSearch() ? setRecordName('') : setRecordId('')
    setSearchResults([])
    setHasSavedRecord(false)
  }

  const renderRecordName = (): React.ReactNode => {
    if (hasSavedRecord === true) {
      return (
        <div id={id} className='input-group-field supplier-selected-name'>
          <div className='selection'>
            <div className='name'>
              {isIdSearch() ? `${recordId} - ${recordName}` : recordName}
            </div>
            <div className='remove' onClick={clearRecordId}>
              <i className='fas fa-times' />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <input
          id={id}
          type='text'
          name={name}
          value={isIdSearch() ? recordId : recordName}
          onChange={(e) => {
            if (props.onChange != null) {
              props.onChange(e)
            }
          }}
          onFocus={executeSearch}
          onBlur={() => {
            setIsSearching(false)
            clearSearch()
          }}
          className='input-group-field'
          autoComplete='off'
          placeholder={placeholder}
        />
      )
    }
  }

  const renderNoResultsForIDMessage = (): React.ReactNode => {
    return (
      <div
        className='create-supplier search-result clearfix'
      >
        <div className='float-left'>No record found with this ID</div>
      </div>
    )
  }

  const renderCreateRecord = (): React.ReactNode => {
    return (
      <div
        className='create-supplier search-result clearfix'
        onMouseDown={clearRecordId}
      >
        <div className='new-supplier-name'>{recordName}</div>
        <div className='float-left' data-testid={`create-${name}-option`}>{`Create ${indefinite(name)} with this name`} &rarr;</div>
        <div className='float-right'>
          <i className='fas fa-plus' />
        </div>
      </div>
    )
  }

  const renderSearchResults = (): React.ReactNode => {
    return (
      <div className={`search-results ${isSearching ? 'show' : ''}`} data-testid='search-results-dropdown'>
        {
          !isIdSearch() && isSearching &&
          renderCreateRecord()
        }
        {
          isIdSearch() && isSearching && searchResults.length === 0 &&
          renderNoResultsForIDMessage()
        }
        <div data-testid='search-results'>
          {
            searchResults.map((result) => {
              return renderSearchResult(result)
            })
          }
        </div>
      </div>
    )
  }

  const saveRecord = (recordId: string, recordName: string): void => {
    setRecordId(recordId)
    setRecordName(recordName)
    setHasSavedRecord(true)
    setIsSearching(false)
    setSearchResults([])
  }

  const renderSearchResult = (result: SearchResult): React.ReactNode => {
    return (
      <div
        className='search-result'
        key={result.id}
        id={`supplier-search-${result.id}`}
        onMouseDown={() => saveRecord(result.id, result.name)}
      >
        <div className='name'>{result.name}</div>
        <div>{result.location}</div>
        {
          isIdSearch() &&
            <div>{result.id}</div>
        }
      </div>
    )
  }

  const hasError = (): boolean => {
    return meta.touched && meta.error !== undefined
  }

  const errorMessage = (): React.ReactNode => {
    if (hasError()) {
      return (
        <div className='error'>{meta.error}</div>
      )
    }
  }

  return (
    <div className='supplier-search' data-testid={`${name}-select`}>
      <label
        className='select optional disabled sub-header'
        htmlFor={props.id}
      >
        {label}
      </label>
      <div className='input-group supplier-name-container'>
        <span className='input-group-label icon'>
          <i className='far fa-search' />
        </span>
        {renderRecordName()}
      </div>

      <input type='hidden' name={idFieldName} value={recordId} />
      <input type='hidden' name={nameFieldName} value={recordName} />

      {renderSearchResults()}
      {errorMessage()}
    </div>
  )
}

SelectSearchableRecord.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  recordSearchPath: PropTypes.string.isRequired,
  searchFields: PropTypes.arrayOf(PropTypes.string.isRequired),
  minimumSearchTermLength: PropTypes.number,
  id: PropTypes.string,
  idFieldName: PropTypes.string,
  nameFieldName: PropTypes.string,
  placeholder: PropTypes.string,
  stateCode: PropTypes.string,
  allowIdSearch: PropTypes.bool,
  additionalSearchQueryParams: PropTypes.any
}

export default SelectSearchableRecord

import { useField } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'

export interface TextInputProps {
  label: string
  id?: string
  type: string
  name: string
  showErrorHighlight?: boolean
  placeholder?: string
  labelLink?: () => React.ReactNode
  className?: string
}

const TextInput: React.FC<TextInputProps> = ({ label, labelLink, showErrorHighlight, ...props }) => {
  const [field, meta] = useField(props)

  const hasError = (): boolean => {
    return meta.touched && meta.error !== undefined
  }

  const inputId = (): string => {
    return props.id ?? props.name
  }

  const errorMessage = (): JSX.Element | undefined => {
    if (hasError()) {
      return (
        <div className='error' role='alert'>{meta.error}</div>
      )
    }
  }
  const errorClass = hasError() && showErrorHighlight === true ? 'error' : ''

  const combinedClassNames = props.className !== undefined ? `${props.className} ${errorClass}` : errorClass

  return (
    <div className='field' data-testid={`${props.name}-text-field`}>
      <label htmlFor={inputId()}>{label}</label>
      {(labelLink != null) ? labelLink() : null}
      <input {...field} {...props} className={combinedClassNames} id={inputId()} />
      {errorMessage()}
    </div>
  )
}

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  showErrorHighlight: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  labelLink: PropTypes.func
}

TextInput.defaultProps = {
  type: 'text',
  showErrorHighlight: false
}

export default TextInput

import React from 'react'
import { agencySearchPath } from '../routes/api'
import PropTypes from 'prop-types'
import SelectSearchableRecord from './SelectSearchableRecord'

export interface SelectAgencyProps {
  stateCode?: string
  label?: string
  id?: string
  name?: string
  recordSearchPath?: string
  placeholder?: string
  searchFields?: string[]
  allowIdSearch?: boolean
  type?: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
}

const SelectAgency: React.FC<SelectAgencyProps> = ({
  stateCode,
  label = 'Organization',
  id = 'select-agency',
  name = 'organization',
  allowIdSearch = false,
  ...props
}) => {
  return (
    <SelectSearchableRecord
      recordSearchPath={agencySearchPath()}
      additionalSearchQueryParams={stateCode !== undefined ? { state: stateCode } : undefined}
      label={label}
      id={id}
      name={name}
      allowIdSearch={allowIdSearch}
      {...props}
    />
  )
}

SelectAgency.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  stateCode: PropTypes.string,
  searchFields: PropTypes.arrayOf(PropTypes.string.isRequired),
  allowIdSearch: PropTypes.bool,
  type: PropTypes.string
}

export default SelectAgency

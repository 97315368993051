import React, { useState, useEffect } from 'react'
import { api } from 'components/api'
import { statesPath } from 'components/routes/api'
import Select from './Select'

interface State {
  code: string
  name: string
}

const SelectState: React.FC = () => {
  const [states, setStates] = useState<State[]>([])

  useEffect(() => {
    api.get<unknown, State[]>({
      method: 'GET',
      url: statesPath()
    }).then(
      response => {
        setStates(response.data)
      }
    ).catch(e => console.error(e))
  }, [])

  return (
    <Select label='Select State' name='state'>
      <option value=''>Select one</option>
      {
        states.map((state) => (
          <option value={state.code} key={state.code}>{state.name}</option>
        ))
      }
    </Select>
  )
}

export default SelectState

import React from 'react'
import { useField } from 'formik'
import PropTypes from 'prop-types'

export interface SelectProps {
  label: string
  id?: string
  name: string
  children?: React.ReactNode
}

const Select: React.FC<SelectProps> = ({ label, ...props }) => {
  const [field, meta] = useField(props)

  const hasError = (): boolean => {
    return meta.touched && meta.error !== undefined
  }

  const selectId = (): string => {
    return props.id ?? props.name
  }

  const errorMessage = (): React.ReactNode => {
    if (hasError()) {
      return (
        <div className='error'>{meta.error}</div>
      )
    }
  }

  return (
    <div className='field'>
      <label htmlFor={selectId()}>{label}</label>
      <select {...field} {...props} id={selectId()} />
      {errorMessage()}
    </div>
  )
}

Select.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  name: PropTypes.string.isRequired
}

export default Select
